import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import FeatherIcon from 'feather-icons-react';
import { Col, Container, Row, Form, InputGroup, Button } from 'react-bootstrap'
import { config } from './config'
import { storeCredentials } from './utils'
import { Alert } from 'react-bootstrap';

interface SignupProps {
  navigate: any
}

type SignupStates = {
  logError: string
  logErrors: any
  logEmail: string
  logName: string
  logPassword: string
}

class Signup extends Component <SignupProps, SignupStates> {
  constructor (props: SignupProps) {
    super(props)
    this.state = {
      logError: '',
      logErrors: {},
      logEmail: '',
      logName: '',
      logPassword: ''
    }
  }

  inputChange = (event: any) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value } as SignupStates)
  }

  register = (e: any) => {
    e.preventDefault()
    fetch(
      config.app.apiUri + '/api/v1/me/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.state.logEmail,
          name: this.state.logName,
          password: this.state.logPassword
        })
      })
      .then((response) => { return response.json() })
      .then((json) => {
        if (json.status === 'success') {
          storeCredentials(json.token)
          this.props.navigate('/dashboard-select')
        } else {
          this.setState({
            logErrors: json.errors,
            logError: json.message
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return (
      <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={4} className="my-5">
              <>
                <h1 className="display-4 text-center mb-3">Sign up</h1>
                <p className="text-muted text-center mb-5">Sign up to access our dashboard.</p>
                <form onSubmit={(e)=>this.register(e)}>
                  <div className="form-group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={this.state.logName} name="logName" onChange={this.inputChange} placeholder="Chuck Norris" />
                  </div>
                  <div className="form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" value={this.state.logEmail} name="logEmail" onChange={this.inputChange} placeholder="name@address.com" />
                  </div>
                  <div className="form-group">
                    <Row>
                      <Col>
                        <Form.Label>Password</Form.Label>
                      </Col>
                      <Col xs="auto">
                        <Form.Text as={Link} className="small text-muted" to="/forgot">
                          Forgot password?
                        </Form.Text>
                      </Col>
                    </Row>
                    <Form.Control type="password" value={this.state.logPassword} name="logPassword" onChange={this.inputChange} placeholder="Enter your password" />
                  </div>
                  <Button size="lg" className="w-100 mb-3" type="submit">
                    Sign up
                  </Button>
                  { this.state.logError !== '' ?
                    <Alert variant="danger" className='text-center' onClose={() => this.setState({logError: ''})} dismissible>
                      {this.state.logError}
                    </Alert>
                  :null}
                  <p className="text-center">
                    <small className="text-muted text-center">
                      Already have an account? <Link to="/sign-in">Sign in</Link>.
                    </small>
                  </p>
                </form>
              </>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default function SignupWithNavigation() {
  const navigate = useNavigate()
  return <Signup navigate={navigate}/>
}